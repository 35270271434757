
import {Component, Vue} from "vue-property-decorator";

import CentralSystemForm from '@/components/CentralSystemForm.vue';
import RouteDialog from "@/components/RouteDialog.vue";
import {CentralSystem, RouteDialogConfirmEvent} from "@/domain";

@Component({
  components: {CentralSystemForm, RouteDialog},
  data: () => ({
    config: undefined,
  })
})
export default class EditCentralSystemDialog extends Vue {

  centralSystem: Partial<CentralSystem> = {
    name: '',
    ocppVersion: '1.6',
    type: 'WEBSOCKET'
  };

  loading: boolean = true;

  async mounted() {
    await this.$store.dispatch('specific/getConfig').then(
        value => this.$data.config = value
    );
    this.centralSystem = {...await this.$store.dispatch("centralSystems/fetchOne", this.$route.params.id)};
    this.loading = false;
  }

  async onConfirm(evt: RouteDialogConfirmEvent) {
    try {
      await this.$store.dispatch("centralSystems/updateOne", {
        id: this.centralSystem.id,
        name: this.centralSystem.name,
        ocppVersion: this.centralSystem.ocppVersion,
        type: this.centralSystem.type,
        uri: this.centralSystem.uri,
      });
      evt.done();
    } catch (e) {
      console.log("An error occurred while updating: ", e);
      evt.cancel();
    }
  }
}
